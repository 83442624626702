<template>
  <div class="mx-2">
    <v-card class="mt-2 d-flex justify-center">
      <!-- <img src="../../assets/img/Frame_dashboard.jpg" alt="" /> -->
      <v-row no-gutters>
        <!--Left Side of Dashboard-->
        <v-col cols="12" sm="12" md="6">
          <v-sheet class="pa-2 ma-2">
            <!--My Core Time-->
            <div>My Core Time</div>
            <div>
              <v-card class="mt-2 d-flex justify-center">
                <v-col cols="3"
                  ><v-row class="my-1 ml-1"
                    ><span v-if="ctType == 1"> {{ year }}</span
                    ><span v-else> {{ year }}</span></v-row
                  ><v-row class="my-1 ml-1"
                    ><v-chip
                      v-if="ctStatus == 2"
                      color="green"
                      class="white--text"
                      small
                    >
                      Approved
                    </v-chip>
                    <v-chip
                      v-else-if="ctStatus == 1"
                      color="orange"
                      class="white--text"
                      small
                    >
                      For Approval
                    </v-chip>
                    <v-chip
                      v-else-if="ctStatus == 3"
                      color="red"
                      class="white--text"
                      small
                    >
                      Pending
                    </v-chip>
                  </v-row></v-col
                >
                <v-col class=""
                  ><v-row
                    class="my-1 ml-1 d-flex justify-center text-caption"
                    >{{ range }}</v-row
                  ><v-row class="my-1 ml-1 justify-center text-caption">{{
                    coreTimeSched
                  }}</v-row></v-col
                >

                <v-col cols="1" class="d-flex justify-end mr-2 mt-md-4 ">
                  <button @click="gotoCoretime()">
                    <v-icon>mdi-chevron-double-right</v-icon>
                  </button></v-col
                >
              </v-card>
            </div>
            <div class="mt-3">My Locator Slip</div>
            <div>
              <v-card class="mt-2 d-flex justify-center text-caption">
                <v-col>
                  <v-row v-if="locator_slip.locator_type == 1" class="my-1 ml-1"
                    >Personal</v-row
                  >
                  <v-row v-if="locator_slip.locator_type == 3" class="my-1 ml-1"
                    >Official</v-row
                  >

                  <v-row class="my-1 ml-1">
                    <v-chip
                      :color="
                        locator_slip.status == 1
                          ? 'orange'
                          : locator_slip.status == 2
                          ? 'green'
                          : locator_slip.status == 3
                          ? 'red'
                          : ''
                      "
                      class="white--text"
                      small
                    >
                      {{
                        locator_slip.status == 1
                          ? "For Approval"
                          : locator_slip.status == 2
                          ? "Approved"
                          : locator_slip.status == 3
                          ? "Pending"
                          : ""
                      }}
                    </v-chip>
                  </v-row></v-col
                >
                <v-col class=""
                  ><v-row class="my-1 ml-1 d-flex justify-center">{{
                    formatDate(locator_slip.inclusive_date)
                  }}</v-row
                  ><v-row class="my-1 ml-1 justify-center text-caption"
                    >{{ locator_slip.out ? formatTime(locator_slip.out) : "" }}
                    -
                    {{
                      locator_slip.in ? formatTime(locator_slip.in) : ""
                    }}</v-row
                  ></v-col
                >
                <v-col cols="3" class="d-flex justify-end my-5 mr-2"
                  ><button @click="gotoLocatorslip()">
                    <v-icon>mdi-chevron-double-right</v-icon>
                  </button></v-col
                >
              </v-card>
            </div>

            <div class="mt-3">Printable's</div>
            <div>
              <v-card class="mt-2 d-flex justify-center">
                <v-col class="ml-md-16"
                  ><v-row class="my-1 ml-1 d-flex justify-center">
                    <img
                      style="border-radius: 10px; border: 1px solid;"
                      src="../../assets/img/Frame_dashboard.jpg"
                      alt=""
                      width="80%"
                      height="80%"
                    />
                  </v-row>
                  <v-row class="my-1 ml-1 d-flex justify-center">MY PDS</v-row>
                  <v-row class="my-1 ml-1 d-flex justify-center"
                    ><button
                      @click="gotoPDS()"
                      class="white--text"
                      style="background-color:  #519043; border-radius: 5px; padding-left: 40px; padding-right: 40px;"
                    >
                      <v-icon class="white--text mr-2" x-small>mdi-plus</v-icon>
                      <span class="text-caption">PRINT</span>
                    </button></v-row
                  >
                </v-col>
                <v-col class="mr-md-16"
                  ><v-row class="my-1 ml-1 d-flex justify-center">
                    <img
                      style="border-radius: 10px; border: 1px solid;"
                      src="../../assets/img/Frame_dashboard.jpg"
                      alt=""
                      width="80%"
                      height="80%"
                    />
                  </v-row>
                  <v-row class="my-1 ml-1 d-flex justify-center "
                    >MY SALN</v-row
                  >
                  <v-row class="my-1 ml-1 d-flex justify-center"
                    ><button
                      @click="gotoSaln()"
                      class="white--text"
                      style="background-color: #519043; border-radius: 5px; padding-left: 40px; padding-right: 40px;"
                    >
                      <v-icon class="white--text mr-2" x-small>mdi-plus</v-icon>
                      <span class="text-caption">PRINT</span>
                    </button></v-row
                  >
                </v-col>
              </v-card>
            </div>
          </v-sheet>
        </v-col>
        <!--Right Side of Dashboard-->
        <v-col cols="12" sm="12" md="6">
          <v-sheet class="pa-2 ma-2">
            <!--My Traveo Order-->
            <div>My Travel Order</div>
            <div>
              <v-card class="mt-2 d-flex justify-center text-caption">
                <v-col
                  ><v-row class="my-1 ml-1">{{ finalTO.travel_purpose }}</v-row
                  ><v-row class="my-1 ml-1">
                    <v-chip
                      :color="
                        finalTO.is_president_approved == 1
                          ? 'orange'
                          : finalTO.is_president_approved == 2
                          ? 'green'
                          : finalTO.is_president_approved == 3
                          ? 'red'
                          : ''
                      "
                      class="white--text"
                      small
                    >
                      {{
                        finalTO.is_president_approved == 1
                          ? "For Approval"
                          : finalTO.is_president_approved == 2
                          ? "Approved"
                          : finalTO.is_president_approved == 3
                          ? "Pending"
                          : ""
                      }}
                    </v-chip>
                  </v-row></v-col
                >
                <v-col class=""
                  ><v-row
                    class="my-1 ml-1 d-flex justify-center text-caption"
                    >{{ finalTO.travel_place }}</v-row
                  ><v-row
                    class="my-1 ml-1 justify-center text-caption text-caption"
                  >
                    {{
                      isValidJSON(finalTO.travel_date_from)
                        ? formatDate(getFirstDate(finalTO.travel_date_from))
                        : formatDate(finalTO.travel_date_from)
                    }}
                    -
                    {{
                      isValidJSON(finalTO.travel_date_to)
                        ? formatDate(getFirstDate(finalTO.travel_date_to))
                        : formatDate(finalTO.travel_date_to)
                    }}
                  </v-row></v-col
                >
                <v-col cols="3" class="d-flex justify-end my-5 mr-2"
                  ><button @click="gotoTravelorder()">
                    <v-icon>mdi-chevron-double-right</v-icon>
                  </button></v-col
                >
              </v-card>
            </div>

            <div class="mt-3">My Leave</div>
            <div>
              <v-card class="mt-2 d-flex justify-center text-caption">
                <v-col
                  ><v-row class="my-1 ml-1">{{ leaveDescription }}</v-row
                  ><v-row class="my-1 ml-1"
                    ><v-chip color="green" class="white--text" small>
                      Approved
                    </v-chip></v-row
                  ></v-col
                >
                <v-col class=""
                  ><v-row
                    class="my-5 ml-5 d-flex justify-center text-lg-subtitle-1 text-caption"
                    >{{ leaveDate }}</v-row
                  ><!-- <v-row class="my-1 ml-1 justify-center text-caption"
                    >August 29, 2023 - August 31, 2023</v-row
                  >--></v-col
                >
                <v-col cols="3" class="d-flex justify-end my-5 mr-2"
                  ><button @click="gotoLeave()">
                    <v-icon>mdi-chevron-double-right</v-icon>
                  </button></v-col
                >
              </v-card>
            </div>

            <!--<div class="mt-3 mb-4">Announcements</div>
            <div>
              <v-card class="mt-2 px-2">
                <v-row>
                  <v-col class="mx-2 my-2"> HIS Announcements (Recent)</v-col>

                  <v-col cols="3" class="d-flex justify-end my-1 mr-2"
                    ><strong
                      ><v-icon>mdi-chevron-double-right</v-icon></strong
                    ></v-col
                  >
                </v-row>
                <div class="pt-3 px-3">
                  <hr class="" />
                </div>

                <v-row class="mx-1 pb-1">
                  <v-col cols="12" md="12" class="my-1"
                    ><v-card class="d-flex justify-center"
                      ><v-col>Schedule for PDS Updating</v-col
                      ><v-col class="text-caption">
                        <v-row class="d-flex justify-center"
                          >Sept 12-15, 2023</v-row
                        >
                        <v-row class="d-flex justify-center"
                          >08:00 AM - 05:00 PM</v-row
                        >
                      </v-col></v-card
                    ></v-col
                  >
                  <v-col cols="12" md="12" class="my-1"
                    ><v-card class="d-flex justify-center"
                      ><v-col>Schedule for PDS Updating</v-col
                      ><v-col class="text-caption">
                        <v-row class="d-flex justify-center"
                          >Sept 12-15, 2023</v-row
                        >
                        <v-row class="d-flex justify-center"
                          >08:00 AM - 05:00 PM</v-row
                        >
                      </v-col></v-card
                    ></v-col
                  >
                </v-row>
              </v-card>
            </div>-->
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>
    <ViewSetDirectHeadDialog :data="head_data" />
  </div>
</template>

<script>
// import BarChart from "../../components/Charts/samples/BarChart.vue";
import ViewSetDirectHeadDialog from "../../components/Dialogs/Views/ViewSetDirectHeadDialog.vue";
export default {
  components: {
    //  BarChart
    ViewSetDirectHeadDialog,
  },
  data: () => ({
    mini: false,
    scheduledWorks: null,
    dispatchWorks: null,
    cancelledWorks: null,
    completedWorks: null,
    datas: [],
    locator_slip: [],
    head_data: {},
    label: [],
    top_clients: [],
    userType: null,
    ctType: null,
    year: null,
    range: null,
    ctStatus: null,
    coreTimeSched: null,
    initializeTO: [],
    finalTO: [],
    employee_leave: [],
    leaveDescription: null,
    leaveDate: null,
    leaveApprove: false,
  }),
  created() {
    // console.log("created");
    if (this.$store.state.expiryDate < Date.now()) {
      this.$store.dispatch("setUser", null);
      this.$store.dispatch("setIsAuthenticated", 0);
      this.render = true;
      this.$router.push("/");
      // location.reload();
    }
  },
  watch: {},
  methods: {
    coreTimeData() {
      this.axiosCall("/my-core-time/getMyCoreTime", "GET").then((res) => {
        this.ctType = res.data[0].ctType;
        // console.log("data", res.data);
        // console.log("weekends", res.data);
        for (let index = 0; index < res.data.length; index++) {
          if (res.data[index].isActive == 1) {
            // console.log("Loop", res.data[index]);
            let data = res.data[index].coreTimeSched;
            for (let j = 0; j < data.length; j++) {
              // console.log("inside loop", data[j].workSched);
              for (let k = 0; k < data[j].workSched.length; k++) {
                // console.log("K", data[j].workSched[k]);

                if (this.coreTimeSched == null) {
                  this.coreTimeSched = data[j].workSched[k].substring(0, 3);
                } else {
                  this.coreTimeSched +=
                    ", " + data[j].workSched[k].substring(0, 3) + " ";
                }
              }
              for (let k = 0; k < data[j].schedTimeList.length; k++) {
                // console.log("K", data[j].workSched[k]);
                if (k == 0) {
                  this.coreTimeSched +=
                    this.formatTime(data[j].schedTimeList[k].timeFrom) +
                    " - " +
                    this.formatTime(data[j].schedTimeList[k].timeTo);
                } else {
                  this.coreTimeSched +=
                    " | " +
                    this.formatTime(data[j].schedTimeList[k].timeFrom) +
                    " - " +
                    this.formatTime(data[j].schedTimeList[k].timeTo);
                }
              }
            }
            if (data.length > 0) {
              this.coreTimeSched += " ; ";
            }
          }
        }
        // console.log("coretime", this.coreTimeSched);
        //From
        var dateFrom = new Date(res.data[0].cyFrom);
        const monthFrom = dateFrom.toLocaleString("default", { month: "long" });
        var ddFrom = String(dateFrom.getDate()).padStart(2, "0");
        //To
        var dateTo = new Date(res.data[0].cyTo);
        const monthTo = dateTo.toLocaleString("default", { month: "long" });
        var ddTo = String(dateTo.getDate()).padStart(2, "0");
        var status = res.data[0].status;
        this.ctStatus = status;
        if (dateFrom.getUTCFullYear() == dateTo.getUTCFullYear()) {
          // this.year = dateFrom.getUTCFullYear();
          this.year = res.data[0].SY;
          this.range =
            monthFrom +
            " " +
            ddFrom +
            ", " +
            dateFrom.getUTCFullYear() +
            " - " +
            monthTo +
            " " +
            ddTo +
            ", " +
            dateTo.getUTCFullYear();
        } else {
          this.year = dateFrom.getUTCFullYear() + "-" + dateTo.getUTCFullYear();
          this.range =
            monthFrom +
            " " +
            ddFrom +
            ", " +
            dateFrom.getUTCFullYear() +
            " - " +
            monthTo +
            " " +
            ddTo +
            ", " +
            dateTo.getUTCFullYear();
        }
      });
    },
    locatorData() {
      this.axiosCall("/my-locator-slip/getAllMyLocators", "GET").then((res) => {
        this.locator_slip = res.data[0];
        // console.log("locator slip", this.locator_slip);
      });
    },
    getFirstDate(data) {
      let dump = JSON.parse(data);
      return dump[0];
    },
    isValidJSON(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    travelorderData() {
      this.axiosCall("/travel-order/getMyTravelOrders", "GET").then((res) => {
        console.log("travel order", res.data);
        if (res.data.length == 0) {
          console.log("IF");
          this.initializeTO = { created_at: "2020-05-21T08:32:40.446Z" };
        } else {
          console.log("Else");
          this.initializeTO = res.data[0];
        }
        this.axiosCall("/travel-order/getTaggedTravelOrders", "GET").then(
          (res) => {
            if (res) {
              console.log("Tagged travel Order", res.data[0]);
              if (this.initializeTO.created_at > res.data[0].createdAt) {
                console.log("TO");
                this.finalTO = this.initializeTO;
              } else {
                console.log("TAG");
                this.finalTO = res.data[0];
              }
            }
          }
        );
      });
    },
    // travelOrderTagged() {
    //   this.axiosCall("/travel-order/getTaggedTravelOrders", "GET").then(
    //     (res) => {
    //       if (res) {
    //         console.log("Tagged travel Order", res.data[0].created);
    //       }
    //     }
    //   );
    // },
    leaveData() {
      let filter = this.$store.getters.getFilterSelected;
      this.axiosCall("/leaves/getMyLeaveApplications/" + filter, "GET").then(
        (res) => {
          this.employee_leave = res.data[0];
          this.leaveDescription = this.employee_leave.types.description;
          console.log("Leave", this.employee_leave);

          const startDateStr = this.employee_leave.inclusive_date_from;
          const endDateStr = this.employee_leave.inclusive_date_to;

          // Convert strings to Date objects
          const startDate = new Date(startDateStr);
          const endDate = new Date(endDateStr);

          // Format the output string
          // const options = { month: 'long', day: 'numeric', year: 'numeric' };
          const month = startDate.toLocaleString("default", { month: "long" });
          const startDay = startDate.getDate();
          const endDay = endDate.getDate();
          const year = startDate.getFullYear();
          let output;
          if (startDay == endDay) {
            output = " " + month + " " + startDay + "," + year;
          } else {
            output = " " + month + " " + startDay + "-" + endDay + "," + year;
          }
          this.leaveDate = output;
          console.log("Date leave", output);
        }
      );
    },

    gotoCoretime() {
      // console.log("COreTime");
      this.$router.push("/" + this.userType + "/my-coretime-designation");
    },
    gotoLocatorslip() {
      // console.log("Locator");
      this.$router.push("/" + this.userType + "/my-locator-slip");
    },
    gotoTravelorder() {
      // console.log("Travel Order");
      this.$router.push("/" + this.userType + "/my-travel-order");
    },
    gotoLeave() {
      // console.log("Leave");
      this.$router.push("/" + this.userType + "/my-leave");
    },
    gotoPDS() {
      this.$router.push("/" + this.userType + "/my-pds");
    },
    gotoSaln() {
      this.$router.push("/" + this.userType + "/my-saln");
    },

    loadMenu() {
      // this.links = JSON.parse(res.data.assign_mods);
      switch (this.$store.state.user.user.usertypeID) {
        case 1:
          this.userType = "admin";

          break;
        case 2:
          if (this.$store.state.user.user.user_roleID == 5) {
            this.userType = "superadmin";
          } else {
            this.userType = "employee";
          }
          break;
        case 3:
          this.userType = "security";

          break;
      }
    },
    initialize() {
      // this.travelOrderTagged();
      this.getIfHasDirectHead();
      this.getMyDirectHead();
      this.loadMenu();
      this.coreTimeData();
      this.locatorData();
      this.travelorderData();
      this.leaveData();
      // this.getMyDirectHead();
      // let statuses = [1,3,5,6]
      // this.axiosCall("/dashboard/getAllWorkStatusCount", "POST", statuses).then((res) => {
      //   if (res){
      //       this.scheduledWorks = res.data.worksArray[0].work_detail_status_count
      //       this.dispatchWorks = res.data.worksArray[1].work_detail_status_count
      //       this.cancelledWorks = res.data.worksArray[2].work_detail_status_count
      //       this.completedWorks = res.data.worksArray[3].work_detail_status_count
      //       this.$refs.DoughnutChart1.renderGraph(res.data.total.total_works,res.data.worksArray[0].work_detail_status_count)
      //       this.$refs.DoughnutChart2.renderGraph(res.data.total.total_works,res.data.worksArray[1].work_detail_status_count)
      //       this.$refs.DoughnutChart3.renderGraph(res.data.total.total_works,res.data.worksArray[2].work_detail_status_count)
      //       this.$refs.DoughnutChart4.renderGraph(res.data.total.total_works,res.data.worksArray[3].work_detail_status_count)
      //   }
      // });
      // this.axiosCall("/dashboard/getAllTopClients", "GET").then((res) => {
      //   if (res){
      //       this.top_clients = res.data
      //   }
      // });
      // this.axiosCall("/dashboard/getAllWorkRequest", "GET",).then((res) => {
      //   if (res){
      //       res.data.forEach(item => {
      //         this.label.push(item.request_type_description)
      //         this.datas.push(item.work_request_count)
      //       });
      //     this.$refs.PieChart.renderGraph(this.datas,this.label)
      //   }
      // });
      // console.log(this.$store.getters.getIsAuthenticated);
    },
    getMyDirectHead() {
      this.axiosCall("/user-details/getMyDirectHead", "GET").then(
        (res) => {
          console.log(res.data);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getIfHasDirectHead() {
      this.axiosCall("/my-direct-head/getIfHasDirectHead", "GET").then(
        (res) => {
          // console.log("hasdirecthead", res.data);
          this.head_data = res.data;
        }
      );
    },
  },
  mounted() {
    // if (this.$store.state.expiryDate < Date.now()) {
    //   this.$store.dispatch("setUser", null);
    //   this.$store.dispatch("setIsAuthenticated", 0);
    //   this.render = true;
    //   this.$router.push("/");

    //   // location.reload();
    // }
    this.initialize();
  },
};
</script>
